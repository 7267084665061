<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader> Customer List </CCardHeader>
					<CCardBody>
						<CRow>
							<CCol md="4">
								<CInput
									label="Filter"
									placeholder="Enter Filter"
									v-model="moreParams.filter"
								/>
							</CCol>
							<CCol md="4">
								<div role="group" class="form-group">
									<CButton
										@click="reset"
										id="btn"
										color="primary"
										style="margin-top: 27px"
									>
										<CIcon name="cil-reload" />
									</CButton>
									<CButton
										@click="add()"
										id="btn"
										color="success"
										style="margin-top: 27px; margin-left: 5px"
									>
										<CIcon name="cil-plus" />
									</CButton>
								</div>
							</CCol>
						</CRow>
						<div class="table-responsive" style="max-height: 900px">
							<vuetable
								v-show="!loadingTable"
								ref="vuetable"
								:api-url="uri"
								:fields="fields"
								:per-page="15"
								pagination-path="pagination"
								data-path="mydata"
								:css="cssVuetable.table"
								:transform="transformData"
								:append-params="moreParams"
								:http-options="httpoption"
								@vuetable:pagination-data="onPaginationData"
								@vuetable:loading="onLoadingTable"
								@vuetable:load-success="onLoadSuccessTable"
							>
								<template slot="slot-actions" slot-scope="props">
									<CButton
										@click="add(props.rowData)"
										color="warning"
										size="sm"
										class="py-0"
									>
										<CIcon name="cil-pencil" />
									</CButton>
								</template>
							</vuetable>
							<div v-show="loadingTable" class="text-center">
								<ellipsis-loader :color="'#54f1d2'" />
							</div>
						</div>
						<vuetable-pagination
							ref="pagination"
							:css="cssVuetable.pagination"
							@vuetable-pagination:change-page="onChangePage"
						>
						</vuetable-pagination>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>

		<CModal
			:show.sync="modal"
			:no-close-on-backdrop="true"
			:centered="true"
			title="Modal title 2"
			size="lg"
			color="info"
		>
			<CRow>
				<CCol md="12">
					<CInput label="Name" v-model="frm.nama" placeholder="Name" />
				</CCol>
			</CRow>
			<CRow>
				<CCol md="6">
					<CInput
						label="No HP"
						type="number"
						v-model="frm.hp"
						placeholder="081234567890"
					/>
				</CCol>
				<CCol md="6">
					<div role="group" class="form-group">
						<label for="sumber" class=""> Sumber </label>
						<select v-model="frm.sumber" id="sumber" class="form-control">
							<option data-key="shopee" value="shopee">shopee</option>
							<option data-key="tokopedia" value="tokopedia">tokopedia</option>
							<option data-key="lazada" value="lazada">lazada</option>
							<option data-key="jdid" value="jdid">jdid</option>
							<option data-key="instagram" value="instagram">instagram</option>
							<option data-key="whatsapp" value="whatsapp">whatsapp</option>
							<option data-key="cod" value="cod">cod</option>
						</select>
					</div>
				</CCol>
				<CCol md="6">
					<CInput label="Kontak" v-model="frm.kontak" placeholder="kontak" />
				</CCol>
			</CRow>
			<CRow>
				<CCol md="12">
					<CInput label="Alamat" v-model="frm.alamat" placeholder="alamat" />
				</CCol>
			</CRow>
			<template #header>
				<h6 class="modal-title">
					{{ selectedId != null ? "Edit " + frm.nama : "Add new data" }}
				</h6>
				<CButtonClose @click="modal = false" class="text-white" />
			</template>
			<template #footer>
				<CButton @click="modal = false" color="danger">CANCEL</CButton>
				<CButton @click="save" :disabled="loading" color="success"
					>SAVE</CButton
				>
			</template>
		</CModal>
	</div>
</template>

<script>
import { basuri, debounce } from "@/plugins/utils";
//import dayjs from "dayjs";

export default {
	data() {
		return {
			uri: basuri + "customer",
			moreParams: { filter: null, t_: Date.now() },
			selectedId: null,
			loading: false,
			modal: false,
			frm: {
				nama: "",
				//dob: new Date(Date.now()).toISOString().split("T")[0],
				hp: "",
				alamat: "",
				sumber: "shopee",
				kontak: "",
			},
			fields: [
				{
					name: "nama",
					title: "Nama",
					sortField: "nama",
				},
				{
					name: "hp",
					title: "HP",
					sortField: "hp",
				},
				{
					name: "alamat",
					title: "Alamat",
					sortField: "alamat",
				},
				{
					name: "kontak",
					title: "Kontak",
					sortField: "kontak",
				},
				{
					name: "sumber",
					title: "Sumber",
					sortField: "sumber",
				},
				{
					name: "slot-actions",
					title: "",
				},
			],
		};
	},
	watch: {
		"moreParams.filter": debounce(function (val) {
			if (val.length > 3 || val.length == 0) this.$refs.vuetable.refresh();
		}, 1000),
	},
	methods: {
		reset() {
			this.moreParams = { filter: null, t_: Date.now() };
			this.$refs.vuetable.refresh();
		},
		add(data = null) {
			if (data) {
				this.frm = JSON.parse(JSON.stringify(data));
				this.selectedId = data.id;
			} else {
				this.frm = {
					nama: "",
					hp: "",
					alamat: "",
					sumber: "shopee",
					kontak: "",
				};
				this.selectedId = null;
			}
			this.modal = true;
		},
		save: debounce(async function () {
			this.loading = true;

			try {
				let datar = {};
				if (this.selectedId) {
					const { data } = await this.$http.put(
						"customer/" + this.selectedId,
						this.frm
					);
					datar = data;
				} else {
					const { data } = await this.$http.post("customer", this.frm);
					datar = data;
				}
				if (datar.status == "success") {
					this.$toast.success("Success ! " + datar.message);
					this.$refs.vuetable.refresh();
					this.modal = false;
				} else {
					this.$swal("Fail !", datar.message, "error");
				}
			} catch (error) {
				this.$swal("Error !", error.message, "error");
			}
			this.loading = false;
		}, 1000),
	},
};
</script>
